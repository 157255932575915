import { Helmet } from 'react-helmet'
import { Column, IconEntry, IconEntryProps, Row } from './icons-grid-elements'

export type IconsGridProps = {
    title: string
    data: IconEntryProps[]
}

export const IconsGrid = (props: IconsGridProps) => {
	const icons = props.data?.map(data => <IconEntry key={data.title} title={data.title} class={data.class} />)

	return (
		<div>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.12.0/devicon.min.css"
				/>
			</Helmet>

			<Column>
				<h3 style={{ flex: 0.25, margin: 'auto', textAlign: 'center' }}>{props.title}</h3>
				<Row>{icons}</Row>
			</Column>
		</div>
	)
}
