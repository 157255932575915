import styled from 'styled-components'
import { BONE } from '../../styles/default'

export const Timeline = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const ExperienceTimelineEntry = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding: 1rem 0;
    padding-right: 1.5rem;
    position: relative;
    /* background-color: ${BONE()}; */
`

export const ExperienceTimelineEntryInitialDate = styled.p`
    flex: 0.25;
    font-size: 1.7rem;
    text-align: center;
    color: black;
    font-weight: 500;
`

export const ExperienceTimelineEntryCompanyName = styled.p`
    text-align: center;
    font-weight: 600;
    justify-content: center;
    align-content: center;
    align-self: center;
    display: inline;
    padding: 0 0.7rem;
`

export const JobDetails = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
`

export const JobDetailsDescription = styled.p`
    padding-top: 1rem;
    text-align: left;
`

export const JobDetailsHeader = styled.div`
    /* text-align: center; */
    background-color: black;
    border-radius: 1rem;
    color: white;
    padding-left: 1rem;
    vertical-align: middle;
    align-content: center;
    padding: 0.3rem 0.5rem;
`
