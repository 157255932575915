import { NamedIconEntryContainer, NamedIconEntry } from './skill-icon-elements'
import React from 'react'

type NamedIconProps = {
    title: string
    icon: string
    color?: string
}

export const NamedIcon = (props: NamedIconProps) => {
	return (
		<NamedIconEntryContainer style={{ backgroundColor: props.color ?? 'white' }}>
			<NamedIconEntry src={props.icon} />
			{props.title}
		</NamedIconEntryContainer>
	)
}
