import styled from 'styled-components'
import React from 'react'

export const ContactDetailsContainer = styled.a`
    display: flex;
    padding: 1rem 0rem;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        text-align: center;
    }
`

export const ContactDetailsEntry = styled.div`
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    padding: 0.5rem 0;
`

export const ContactIcon = styled.i`
    font-size: 2rem;
    display: inline-block;
    vertical-align: bottom;
    padding-right: 1rem;
`

export type ContactDetailsIconProps = {
    iconClassName: string
}

export const ContactDetailsIcon = (props: ContactDetailsIconProps) => {
	return <ContactIcon className={props.iconClassName} />
}
