import styled from 'styled-components'

type ScrollSectionSeparatorProps = {
    size?: number
}

const defaultSize = 4

export const ScrollSectionSeparator = styled.div<ScrollSectionSeparatorProps>`
    display: flex;
    background: black;
    color: white;
    text-align: center;
    justify-content: center;
    align-content: center;
    padding: ${props => `${props.size ?? defaultSize}rem 0rem`};
`
