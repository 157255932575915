import { IconsGrid } from '../elements-grid/icons-grid'
import { OverflowableRow } from '../overflowable-row/overflowable-row'
import { PerkContainer, PerkContainerEntry } from '../perks-container/perk-container'
import {
	ExperienceTimelineEntry,
	ExperienceTimelineEntryCompanyName,
	ExperienceTimelineEntryInitialDate,
	JobDetails,
	JobDetailsDescription,
	JobDetailsHeader,
	Timeline,
} from './experience-timeline-elements'
import { ReactComponent as MxSVG } from '../../resources/mx.svg'
import { ReactComponent as FrSVG } from '../../resources/fr.svg'
import { ReactComponent as GbSVG } from '../../resources/gb.svg'

export type ExperienceTimelineEntryData = {
    initialDate: string
    position?: string
    companyName: string
    finalDate?: string
    description?: string
    bullets?: string[]
    logo?: string
}

type ExperienceTimelineProps = {
    entries: ExperienceTimelineEntryData[]
}

export const ExperienceTimeline = (props: ExperienceTimelineProps) => {
	const getItemBullets = (itemBullets: string[]) => {
		const bullets = itemBullets.map(bullet => <li key={bullet.slice(0,5)}>{bullet}</li>)
		return <ul style={{ paddingLeft: '2rem' }}>{bullets}</ul>
	}

	const getItemLogo = (logoUrl?: string) =>
		logoUrl ? (
			<div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
				<img
					src={logoUrl}
					alt=""
					style={{
						height: '1.3rem',
						backgroundColor: 'white',
						justifyItems: 'center',
						borderRadius: '1rem',
						verticalAlign: 'middle',
						marginBottom: '0.2rem',
					}}
				/>
			</div>
		) : (
			<div />
		)

	const items = props.entries.map(itemData => {
		return (
			<ExperienceTimelineEntry key={itemData.companyName}>
				<ExperienceTimelineEntryInitialDate>
					{itemData.initialDate}
				</ExperienceTimelineEntryInitialDate>
				<JobDetails>
					<JobDetailsHeader>
						{getItemLogo(itemData.logo)}
						<ExperienceTimelineEntryCompanyName>
							{itemData.companyName}
						</ExperienceTimelineEntryCompanyName>
					</JobDetailsHeader>
					{itemData.description ? (
						<JobDetailsDescription>{itemData.description}</JobDetailsDescription>
					) : (
						<div />
					)}
					{itemData.bullets ? getItemBullets(itemData.bullets) : <div />}
				</JobDetails>
			</ExperienceTimelineEntry>
		)
	})

	return (
		<Timeline>
			<div>{items}</div>
		</Timeline>
	)
}
