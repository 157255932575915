import styled from 'styled-components'

export const Column = styled.div`
    display: flex;
    padding: 1rem 1rem;
    /* width: 100%; */
    flex-direction: column;

    @media only screen and (max-width: 600px) {
        text-align: start;
        justify-content: flex-start;
    }
`

export const Row = styled.div`
    display: inline-block;
    /* flex-direction: row; */
    justify-content: center;
    text-align: center;
    /* font-size: 35px; */
    padding: 0.5rem 0;
    /* flex: 1; */
    /* flex-wrap: wrap; */
    width: 100%;
    /* text-align: justify; */
`

export const IconTitle = styled.p`
    /* display: flex; */
    font-size: 0.8rem;
    text-align: center;
`

export const IconContainer = styled.div`
    display: inline-block;
    flex-direction: column;
    align-items: center;
    /* flex: 1; */
    padding: 0 1rem;

    & i {
        font-size: 2.5rem;
    }
`

export type IconEntryProps = {
    title: string
    class: string
}

export const IconEntry = (props: IconEntryProps) => {
	return (
		<IconContainer>
			<i className={props.class} />
			<IconTitle>{props.title}</IconTitle>
		</IconContainer>
	)
}
