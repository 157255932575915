import { Perk } from './perk-container-elements'

export type PerkContainerEntry = {
    name: string
    icon?: any
}

export type PerkContainerProps = {
    title: string
    entries: PerkContainerEntry[]
}

export const PerkContainer = (props: PerkContainerProps) => {
	const entries = props.entries.map(entry => {
		const icon = entry.icon ? (
				entry.icon
		) : (
			<div></div>
		)
		return (
			<Perk>
				{icon}
				{entry.name}
			</Perk>
		)
	})

	return (
		<div style={{ padding: '1rem 0' }}>
			<h2 style={{ textAlign: 'center' }}>{props.title}</h2>
			<div style={{}}>{entries}</div>
		</div>
	)
}
