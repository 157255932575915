import styled from 'styled-components'

export const SideBySideContainer = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0rem 2rem;
    padding-top: 4rem;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        padding: 0rem 1rem;
        padding-top: 1rem;
    }
`

export const SideBySideContainerTitle = styled.div`
    flex: 0.5;
    text-align: 'center';
    justify-content: 'center';
    align-content: 'center';
    font-weight: 600;
    font-size: 1.4rem;
`

export const SideBySideContainerDescription = styled.p`
    flex: 1;
`

export const DescriptionContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`
