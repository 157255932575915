import { Helmet } from 'react-helmet'
import { ContactDetails } from '../../components/contact-details/contact-details'
import {
	ExperienceTimeline,
	ExperienceTimelineEntryData,
} from '../../components/experience-timeline/experience-timeline'
import { NamedIcon } from '../../components/named-icon/skill-icon'
import { OverflowableRow } from '../../components/overflowable-row/overflowable-row'
import { PresentationCard } from '../../components/presentation-card/presentation-card'
import { ScrollSectionSeparator } from '../../components/scroll-section-separator/scroll-section-separator-elements'
import { SideBySideFlexContainer } from '../../components/side-by-side-container/side-by-side-container'
import React, { useContext, useState } from 'react'
import { DataProviderContext } from '../../context/data-provider-context'
import { BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClipLoader, ClockLoader, DotLoader, FadeLoader, GridLoader, HashLoader, MoonLoader, PropagateLoader, PuffLoader } from 'react-spinners'
import { GREEN } from '../../styles/default';
import { IconsGrid } from '../../components/elements-grid/icons-grid'
import { PerkContainer, PerkContainerEntry } from '../../components/perks-container/perk-container'
import { ReactComponent as MxSVG } from '../../resources/mx.svg'
import { ReactComponent as FrSVG } from '../../resources/fr.svg'
import { ReactComponent as GbSVG } from '../../resources/gb.svg'

export const Home = () => {

	const dataProvider = useContext(DataProviderContext);
	const [cvData, setCvData] = useState<any>()
	let page;

	if (!cvData) {
		dataProvider.getData().then((val) => {
		 	setCvData(val)
		 })

		page = (
			<div style={{display: "flex", alignItems: 'center', justifyContent: 'center', height: "100%"}}>
				<h2 style={{flex: 1, textAlign: 'center'}}>
					<PuffLoader size={70} color={GREEN()} />
				</h2>
			</div>
		)
	} else {
		const experience: ExperienceTimelineEntryData[] = cvData.expertise;
	
		const flagStyle = {
			height: '1.5rem',
			display: 'inline-block',
			verticalAlign: 'bottom',
			borderRadius: '5px',
			marginRight: '5px',
			marginBottom: '5px'
		};
		const languages: PerkContainerEntry[] = [
			{ name: 'Spanish (Native)', icon:  <MxSVG style={flagStyle}/>},
			{ name: 'English (Fluent)', icon: <GbSVG style={flagStyle}/> },
			{ name: 'French (Basic)', icon: <FrSVG style={flagStyle}/> },
		]

		page = (
			<div>
				<Helmet>
					<title>Erick Siordia Nagaya</title>
				</Helmet>
				<PresentationCard />
				<div>
					<SideBySideFlexContainer title="Who am I?" description={cvData.whoAmI}/>
					<SideBySideFlexContainer title="What can I do?" description={cvData.whatCanIDo}/>
					<OverflowableRow>
						<div style={{ display: 'flex', flex: 1 }}>
							<NamedIcon
								title="Mobile Development"
								icon="https://img.icons8.com/ios-filled/100/000000/smartphone-tablet.png"
							/>
							<NamedIcon
								title="Back-End Development"
								icon="https://img.icons8.com/wired/100/000000/server.png"
							/>
						</div>
						<div style={{ display: 'flex', flex: 1 }}>
							<NamedIcon
								title="Front-End Development"
								icon="https://img.icons8.com/ios-filled/100/000000/bottom-navigation-toolbar.png"
							/>
							<NamedIcon
								title="Automation Development"
								icon="https://img.icons8.com/ios-filled/100/000000/robot.png"
							/>
						</div>
					</OverflowableRow>
				</div>
				<ScrollSectionSeparator size={2}>
					<h2>My expertise</h2>
				</ScrollSectionSeparator>
				<ExperienceTimeline entries={experience} />
				<div>
					<IconsGrid data={cvData.programmingLanguages} title="Technical Languages" />
					<IconsGrid data={cvData.platforms} title="Platforms & Tools" />
				</div>
				<OverflowableRow style={{ justifyContent: 'space-around' }}>
					<PerkContainer title="Languages" entries={languages} />
					<PerkContainer title="Other Perks" entries={cvData.perks} />
				</OverflowableRow>
				<ScrollSectionSeparator>
					{/*eslint-disable-next-line react/no-unescaped-entities*/}
					<h2>Let's build something together!</h2>
				</ScrollSectionSeparator>
				<ContactDetails cvUrl={cvData.cv.url} />
				<div style={{ fontSize: '0.8rem' }}>
					<p style={{ display: 'inline' }}>Icons from:</p>
					<a style={{ padding: '0rem 1rem' }} href="https://icons8.com">Icons8</a>
					<a href="https://devicon.dev">DevIcon</a>
				</div>
			</div>
		)

	}

	return page;


}
