import { ReactComponent as MailSVG } from '../../resources/mail.svg'
import { ReactComponent as CvSVG } from '../../resources/cv.svg'
import React from 'react'
import {
	ContactDetailsContainer,
	ContactDetailsEntry,
	ContactDetailsIcon,
} from './styles'

interface ContactDetailsProps {
	cvUrl: string
}

export const ContactDetails = (props: ContactDetailsProps) => {
	return (
		<ContactDetailsContainer>
			<ContactDetailsEntry>
				<div>
					<a href="mailto:aquierick@gmail.com">
						<MailSVG
							style={{
								height: '2rem',
								display: 'inline-block',
								verticalAlign: 'bottom',
								paddingRight: '1rem',
							}}
						/>
                        aquierick@gmail.com
					</a>
				</div>
			</ContactDetailsEntry>
			<ContactDetailsEntry>
				<div>
					<a href="https://gitlab.com/aquierick">
						<ContactDetailsIcon iconClassName="devicon-gitlab-plain colored" />
                        Erick Nagaya
					</a>
				</div>
			</ContactDetailsEntry>
			<ContactDetailsEntry>
				<div>
					<a href="https://www.linkedin.com/in/erick-siordia-nagaya-a23bb4163/">
						<ContactDetailsIcon iconClassName="devicon-linkedin-plain colored" />
                        Erick Nagaya
					</a>
				</div>
			</ContactDetailsEntry>
			<ContactDetailsEntry>
				<div>
					<a href={props.cvUrl}>
						<CvSVG
							style={{
								height: '2rem',
								display: 'inline-block',
								verticalAlign: 'bottom',
								paddingRight: '1rem',
							}}
						/>
                        Download my CV
					</a>
				</div>
			</ContactDetailsEntry>
		</ContactDetailsContainer>
	)
}
