import {
	DescriptionContainer,
	SideBySideContainer,
	SideBySideContainerDescription,
	SideBySideContainerTitle,
} from './side-by-side-container-elements'

type SideBySideFlexContainerProps = {
    title: string
    description: string
    inverted?: boolean
    first?: any
    second?: any
}

export const SideBySideFlexContainer = (props: SideBySideFlexContainerProps) => {
	const description = (
		<SideBySideContainerDescription>{props.description}</SideBySideContainerDescription>
	)
	const title = <SideBySideContainerTitle>{props.title}</SideBySideContainerTitle>

	const first = props.inverted ? description : title

	const firstObject = props.first ?? first

	return (
		<SideBySideContainer>
			{firstObject}
			<DescriptionContainer>
				{description}
				{props.second ?? <></>}
			</DescriptionContainer>
		</SideBySideContainer>
	)
}
