const opaqueColor: (r: number, g: number, b: number, a?: number) => string = (r, g, b, a) =>
	`rgb(${r}, ${g}, ${b}, ${a ?? 1})`

const generateOpaqueColor: 
	(defaultR: number, defaultG: number, defaultB: number, defaultA?: number) => (r?: number, g?: number, b?: number, a?: number) => string 
		= (defaultR, defaultG, defaultB, defaultA) => (r?: number, g?: number, b?: number, a?: number) => opaqueColor(r ?? defaultR, g ?? defaultG, b ?? defaultB, a ?? defaultA ?? 1)

export const PURPLE: (r?: number, g?: number, b?: number, a?: number) => string = generateOpaqueColor(77, 73, 190)
export const BONE: (r?: number, g?: number, b?: number, a?: number) => string  = generateOpaqueColor(248, 242, 206)
export const GREEN: (r?: number, g?: number, b?: number, a?: number) => string = generateOpaqueColor(72, 213, 151)
