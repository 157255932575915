import {
	CircularContainer,
	NameContainer,
	PresentationCardContainer,
	ProfileImage,
} from './presentation-card-elements'
import profile from '../../resources/profile.jpg'

export const PresentationCard = () => {
	return (
		<PresentationCardContainer>
			<CircularContainer size={220} margin={30}>
				<ProfileImage src={profile} width={'95%'} height={'95%'} />
			</CircularContainer>

			<NameContainer>
				<h1 style={{ fontSize: '50px' }}>Erick Siordia Nagaya</h1>
				<h3>Full Stack Developer</h3>
			</NameContainer>
		</PresentationCardContainer>
	)
}
