import { Provider } from "./provider";

export class RestDataProvider implements Provider{
    endpoint = process.env.NODE_ENV?.includes("dev") ? "http://localhost:8080" : "https://siordia.dev/data";
    response: any;

    async getKey(key: String) {
        const parsedResponse = await this.getData();
        const splittedPath: string[] = key.split(".").reverse();
        
        let value = parsedResponse;
        if (key === "") return value;

        for (const item of splittedPath) {
            if (Object.keys(value).includes(item))
                value = value[item]
            else return;
        }
        
        return value;
    }

    async getData() {
        if (!this.response)
            this.response = await (await fetch(this.endpoint)).json();
        return this.response;
    }
}