import styled from 'styled-components'
import background from '../../resources/background.jpg'

export const PresentationCardContainer = styled.div`
    display: flex;
    padding: 5rem 0rem 1rem 0;
    justify-content: center;
    background-image: linear-gradient(transparent, white), url(${background});
    background-position: top;

    @media only screen and (max-width: 840px) {
        flex-direction: column;
        align-items: center;
    }
`

export const NameContainer = styled.div`
    padding: 0 2rem;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* 
    @media only screen and (min-width: 600px) {
        text-align: start;
        justify-content: flex-start;    
    } */
`

export const ProfileImage = styled.img`
    border-radius: 50%;
    display: flex;
`

type CircularContainerProps = {
    size: number
    margin?: number
}

export const CircularContainer = styled.div<CircularContainerProps>`
    display: flex;
    border-radius: 50%;
    background-color: white;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    align-items: center;
    justify-content: center;
    margin: ${props => props.margin ?? 0}px;
`
