import styled from 'styled-components'

export const NamedIconEntryContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 2rem 0;

    @media only screen and (max-width: 600px) {
        font-size: 0.9rem;
    }
`

export const NamedIconEntry = styled.img`
    height: 4rem;
    width: 4rem;
    align-self: center;

    @media only screen and (max-width: 600px) {
        height: 3rem;
        width: 3rem;
    }
`
